@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('../public/Roboto-Regular.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}

/* Configurator */

.configurator-container {
  background-color: white;
  border: 4px solid #0051ff36;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  max-width: 800px;
  min-height: 470px;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.step-container.six .button-container {
  width: 70%;
}

.configurator-heading {
  font-size: 2.25rem;
}

.configurator-container {
  padding: 20px;
}

.icon-parent-container.last-page span {
  text-align: center;
}

.step-parent-container {
  display: flex;
  min-height: 300px;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.thanks-child-container {
  justify-content: center;
  display: flex;
  margin: 20px 20px 50px 20px;
  background-color: rgb(245, 245, 245);
  padding: 10px;
  border-radius: 10px;
}


.step-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 33.3%;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px;
  border-radius: 8px;

}

.input-field-container {
  display: flex;
}

.input-field-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.33%;
}

.image-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

img {
  max-width: 45%;
}

.button-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
}

.button-container-main {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button-container-child {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 80%;
}

.button-container.confirm {
  align-items: center;

}

.form-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.main-form {
  display: flex;
  width: 100%;
  height: 100%;
}

.main-div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.form-input {
  width: 100%;
}

.input-container {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.input-label-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button-container.input-people {
  display: flex;
  flex-direction: column;
}

.input-label-container input {
  width: 2.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.input-container select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.check-input.datenschutz {
  margin-top: 10px;
}
.check-input.disclaimer {
  margin-top: 10px;
  align-items: flex-start;
}

textarea#w3review {
  border: 1px solid #ccc;
  border-radius: 5px;
}

input#fname {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.input-container select {
  font-size: 11px;
}

.button-container.input-wish {
  width: 80%;
}

.btn-primary:disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: not-allowed;
}

.btn-primary.red {
  background-color: #046c9954 !important;
  border-color: #046c9954 !important;
  color: rgb(0, 0, 0);
}

.btn-primary.green {
  background-color: #046c9954 !important;
  color: rgb(0, 0, 0);
  border-color: #046c9954 !important;
}

.image-active {
  filter: invert(64%) sepia(69%) saturate(3056%) hue-rotate(167deg) brightness(95%) contrast(80%);
}

textarea#w3review {
  max-width: 200px;
}

.disabled {
  pointer-events: none;
  opacity: 0.25;
  transition: 1s;
}

.step-container {
  transition: opacity 1s;
}

.btn {
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 2px;
  font-weight: 600;
}

.submit {
  background-color: #009fe3;
  border-color: #009fe3;
  color: white;
  padding: 0px 20px;
  border: 0px;
  border-radius: 5px;
}

h6 {
  font-size: 14px;
}

.crit-container {
  display: flex;
  justify-content: center
}

.container-question {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

button.btn.btn-primary {
  background: white;
  border: 1px solid #ccc;
  padding: 5px 20px;
}

select {
  max-width: 150px;
}

.form-input {
  display: flex;
  flex-direction: column
}

label {
  text-align: left;
}

li {
  text-align: left;
}

ul {
  padding-inline-start: 30px;
}

label[for="check"] {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  /* padding-left: 14px; */
}

.check-input {
  display: flex;
  /* padding-left: 10px; */
  font-size: 14px;
}

.text-percentage {
  display: flex;
  background-color: #009fe3;
  color: white;
  font-weight: 600;
  justify-content: center;
  width: fit-content;
  padding: 10px 5px;
  border-radius: 25px;
  margin-bottom: -25px;
  z-index: 2;
}

.submit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.submit-parent-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.submit-parent-container h2 {
  width: 80%;
}



[hidden] {
  display: none;
}


input#partner,
input#phone,
input#mail {
  width: 90%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 5px;
}

.icon-parent-container.last-page {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.icon-parent-container {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.icon-container {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin: 5px 0px;
}

.icon-container img {
  max-width: 25px;
  margin-right: 10px;
}

.icon-container span {
  text-align: left;
  margin-left: 5px;
}


@media (max-width: 900px) {

  .step-container.disabled {
    pointer-events: none;
    opacity: 0.3;
    width: 1px;
    overflow: hidden;
  }

  .step-container.disabled .container-question {
    opacity: 0;
  }

  .step-container.disabled::before {
    background-color: #009fe3;
    color: white;
    padding: 3px 10px;
    border-radius: 25px;
    position: absolute;
    transform: translate(0%, 0%);
  }

  .step-container.one.disabled::before {
    content: "1";
  }

  .step-container.two.disabled::before {
    content: "2";
  }

  .step-container.three.disabled::before {
    content: "3";
  }

  .step-container.four.disabled::before {
    content: "4";
  }

  .step-container.five.disabled::before {
    content: "5";
  }

  .step-container.six.disabled::before {
    content: "6";
  }

  .step-container.seven.disabled::before {
    content: "7";
  }

  .step-container.eight.disabled::before {
    content: "8";
  }

  .step-container.nine.disabled::before {
    content: "9";
  }

}

@media (max-width: 600px) {

  .input-field-container {
    display: flex;
    flex-direction: column;
    /* padding-left: 30px; */
  }

  input#partner,
  input#phone,
  input#mail {
    width: max-content;
  }

  .check-input {
    display: flex;
    /* padding-left: 27px; */
    font-size: 14px;
  }


  label[for="check"] {
    text-align: left;
    /* padding-left: 30px; */
  }

  .input-field-child {
    align-items: flex-start;
  }

  .configurator-heading {
    font-size: 1.75rem;
  }
}

@media (max-width: 433px) {
  .step-container {
    width: 100%;
  }

  textarea#w3review {
    max-width: 140px;
  }
}